// extracted by mini-css-extract-plugin
export var h1 = "block-module--h1--1uV04";
export var bigPoint = "block-module--bigPoint--HtqAb";
export var text = "block-module--text--2kF2C";
export var imageContainer = "block-module--imageContainer--1kBNg";
export var imageDescription = "block-module--imageDescription--2YrLF";
export var imageWrapper = "block-module--imageWrapper--38vyv";
export var colourSchemeDark = "block-module--colourSchemeDark--6-UJv";
export var colourSchemeLight = "block-module--colourSchemeLight--1WZ9x";
export var colourSchemeYellow = "block-module--colourSchemeYellow--3xfax";
export var imagePositionLeft = "block-module--imagePositionLeft--iFJ25";
export var imagePositionRight = "block-module--imagePositionRight--3ZTY9";
export var imagePositionBottom = "block-module--imagePositionBottom--1LZhE";
export var carouseltrue = "block-module--carouseltrue--2QpFx";
export var scroll = "block-module--scroll--18UW-";
export var displayChildrenSlideshow = "block-module--displayChildrenSlideshow--19Cm7";
export var Selected = "block-module--Selected--1luLG";
export var displayChildrenGallery = "block-module--displayChildrenGallery--2apF4";
export var children = "block-module--children--_B1YZ";
export var indicator = "block-module--indicator--2P2Ql";
export var indicatorSelected = "block-module--indicatorSelected--2dyhX";
export var dotContainer = "block-module--dotContainer--hcVn8";
export var post = "block-module--post--17iOO";
export var postName = "block-module--postName--1S3vy";
export var postContainer = "block-module--postContainer--2y3YF";
export var date = "block-module--date--yMraC";
export var postImage = "block-module--postImage--1EihQ";
export var buttonWrapperLeft = "block-module--buttonWrapperLeft--HVC34";
export var buttonWrapperCenter = "block-module--buttonWrapperCenter--krJAP";
export var buttonWrapperRight = "block-module--buttonWrapperRight--2DbTO";
export var button = "block-module--button--D2cwh";
export var displayChildrenSidebySide = "block-module--displayChildrenSidebySide--1MrbV";