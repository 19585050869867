import React, { ReactNode } from "react";
import { BLOCKS, INLINES, Block, Inline } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { RichTextModel, ArticleReference } from "../models/richtext-model";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ImageContainer } from "./imageContainer";
import * as postCss from "../styles/post.module.scss";

interface RichTextProps {
    text: RichTextModel;
    className: string;
    referencesClassName?: string;
    component?: "h1" | "div";
}

export const RichText: React.FC<RichTextProps> = ({ text, component, className, referencesClassName }) => {
    if (text === null) {
        return null;
    } else {
        const referenceList = text.references?.filter((x) => x.__typename === "ContentfulArticleReference");
        const referenceNumbers = referenceList?.reduce((r, x, i) => ({ ...r, [x.contentful_id]: i + 1 }), {});

        const options = {
            renderMark: {},
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline, content: ReactNode) => {
                    return <ImageContainer images={[node.data.target]} css={postCss} />;
                },
                [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline, content: ReactNode) => {
                    switch (node.data.target.__typename) {
                        case "ContentfulCodeSnippet":
                            return (
                                <SyntaxHighlighter
                                    language={node.data.target.language}
                                    style={monoBlue}
                                    showLineNumbers
                                >
                                    {node.data.target.code.code}
                                </SyntaxHighlighter>
                            );
                    }
                },
                [INLINES.HYPERLINK]: (node: Block | Inline, content: ReactNode) => (
                    <a target="_blank" href={node.data.uri}>
                        {content}
                    </a>
                ),
                [INLINES.ASSET_HYPERLINK]: (node: Block | Inline, content: ReactNode) => {
                    return (
                        <a target="_blank" href={node.data.target.file.url}>
                            {content}
                        </a>
                    );
                },
                [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, content: ReactNode) => {
                    switch (node.data.target.__typename) {
                        case "ContentfulPage":
                            return <a href={node.data.target.slug}>{content}</a>;
                        case "ContentfulPost":
                            return <a href={node.data.target.slug}>{content}</a>;
                        case "ContentfulArticleReference":
                            return (
                                <>
                                    {content}{" "}
                                    <sup>
                                        <a href={`#ref_${node.data.target.contentful_id}`}>
                                            [{referenceNumbers[node.data.target.contentful_id]}]
                                        </a>
                                    </sup>
                                </>
                            );
                        case "ContentfulAsset":
                            return (
                                <a target="_blank" href={node.data.target.asset.file.url}>
                                    {content}
                                </a>
                            );
                    }
                },
            },
        };
        const Component = component || "div";

        return (
            <Component className={className}>
                {renderRichText(text, options)}
                {referenceList?.length && (
                    <div className={referencesClassName}>
                        <h3>References:</h3>
                        <ol>
                            {referenceList.map((r: ArticleReference, i) => {
                                const referenceString = `${r.authors}. (${r.year}). ${r.studyTitle}.`;
                                return (
                                    <li key={i} className={className} id={`ref_${r.contentful_id}`}>
                                        {referenceString}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                )}
            </Component>
        );
    }
};
