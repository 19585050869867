import * as React from "react";
import Helmet from "react-helmet";
import { CompanyModel } from "../models";

interface IMetaProps {
    page: {
        meta: {
            title: string;
            description: string;
        };
        createdAt: string;
        updatedAt: string;
    };
    company: CompanyModel;
}

const Meta: React.FC<IMetaProps> = ({ page, company }) => {
    return (
        <Helmet htmlAttributes={{ lang: "en" }}>
            <title>{page.meta.title}</title>
            <meta content="text/html;charset=utf-8" http-equiv="Content-Type" />
            <meta content="utf-8" http-equiv="encoding" />
            <meta name="description" content={page.meta.description} />
            <meta property="og:title" content={page.meta.title} />
            <meta property="og:description" content={page.meta.description} />
            <script type="application/ld+json">
                {`{
            "@graph":[
              {
                "@id":"${company.meta.website}/#organization",
                "url":"${company.meta.website}/",
                "logo":{"@id":"${company.meta.website}/#local-main-organization-logo"},
                "name":"${company.companyName}",
                "@type":["Organization","Place","LocalBusiness"],
                "email":"${company.emailAddress}",
                "image":{"@id":"${company.meta.website}/#local-main-organization-logo"},
                "sameAs":["${company.linkedInUrl}"],
                "address":{"@id":"${company.meta.website}/#local-main-place-address"},
                "telephone":["${company.mobilePhone}"],
                "areaServed":"${company.meta.areaServed}",
                "priceRange":"${company.meta.priceRange}",
                "openingHoursSpecification":[${company.meta.openingHours?.map((r) => r.internal.content).join(",")}]
              },
              {
                "@id":"${company.meta.website}/#website",
                "url":"${company.meta.website}/",
                "name":"${company.companyName}",
                "@type":"WebSite",
                "publisher":{"@id":"${company.meta.website}/#organization"},
                "inLanguage":"en-NZ",
                "description":"${company.meta.description}",
                "potentialAction":[
                  {
                    "@type":"SearchAction",
                    "target":"${company.meta.website}/?s={search_term_string}",
                    "query-input":"required name=search_term_string"
                  }
                ]
              },
              {
                "@id":"${company.meta.website}/#webpage",
                "url":"${company.meta.website}/",
                "name":"${company.companyName} | ${company.meta.description}",
                "@type":"WebPage",
                "about":{"@id":"${company.meta.website}/#organization"},
                "isPartOf":{"@id":"${company.meta.website}/#website"},
                "breadcrumb":{"@id":"${company.meta.website}/#breadcrumb"},
                "inLanguage":"en-NZ",
                "description":"${company.meta.callToAction}",
                "dateModified":"${page.updatedAt}",
                "datePublished":"${page.createdAt}",
                "potentialAction":[{"@type":"ReadAction","target":["${company.meta.website}/"]}]
              },
              {
                "@id":"${company.meta.website}/#breadcrumb",
                "@type":"BreadcrumbList",
                "itemListElement":[{"item":{"@id":"${company.meta.website}/#webpage"},"@type":"ListItem","position":1}]
              },
              {
                "@id":"${company.meta.website}/#local-main-organization-logo",
                "url":"${company.logo.file.url}",
                "@type":"ImageObject",
                "width":${company.logo.file.details.image.width},
                "height":${company.logo.file.details.image.height},
                "caption":"${company.companyName}",
                "contentUrl":"${company.logo.file.url}",
                "inLanguage":"en-NZ"
              }
            ],
            "@context":"https://schema.org"
          }`}
            </script>
        </Helmet>
    );
};

export default Meta;
