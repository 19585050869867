import React, { useEffect, useRef, useState } from "react";
import { ImageModel } from "../models";

const widthStep = 100.0;

const getScale = (htmlEl: HTMLElement, image: ImageModel): [number, string] => {
    const imageAspectRatio = (1.0 * image.file.details.image.width) / image.file.details.image.height;
    const elementAspectRatio = (1.0 * htmlEl.offsetWidth) / htmlEl.offsetHeight;
    let direction = "";
    var scale =
        imageAspectRatio <= elementAspectRatio
            ? //image is proportionally higher than the element - load enough width and it will crop the height
              widthStep * Math.ceil((htmlEl.offsetWidth * window.devicePixelRatio) / widthStep)
            : //image is propotionally wider than the element - load enough height and it will crop the width
              widthStep * Math.ceil(((htmlEl.offsetHeight * window.devicePixelRatio) / widthStep) * imageAspectRatio);

    //if we could not calculate the scale (htmlEl does not have size)
    //or if the desired img size is more than the original
    // then load the original image size
    if (!scale || scale > image.file.details.image.width) {
        scale = image.file.details.image.width;
    }

    imageAspectRatio <= elementAspectRatio ? (direction = "w") : (direction = "h");
    return [scale, direction];
};

export type FPImage = { image: ImageModel; focalPoint: { focalPoint: { x: number; y: number } } };

interface ImageProps
    extends Omit<
        React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
        "src" | "alt" | "title"
    > {
    image: ImageModel | FPImage;
    fit?: string;
}

export const Image: React.FunctionComponent<ImageProps> = ({ image, fit, ...props }) => {
    let asset = {} as ImageModel;
    if ((image as ImageModel).file) {
        asset = image as ImageModel;
    } else if ((image as FPImage).image.file) {
        asset = (image as FPImage).image;
    }
    const isSvg = asset && asset.file.contentType.indexOf("svg") >= 0;
    const [url, setUrl] = useState(
        image && (isSvg ? asset.file.url : `${asset.file.url}?w=${asset.file.details.image.width}&fit=${fit || ""}`)
    );
    const ref = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (typeof window === "undefined") return;
        if (!image) return;

        const handleResize = () => {
            const htmlEl = ref.current;
            if (!htmlEl) return;

            const scale = getScale(htmlEl, asset)[0];
            const direction = getScale(htmlEl, asset)[1];

            let params = {};

            if (direction === "w") {
                params = {
                    w: scale, //widthStep * Math.ceil((htmlEl.width * window.devicePixelRatio) / widthStep),
                    fit,
                };
            } else {
                params = {
                    h: scale, //widthStep * Math.ceil((htmlEl.height * window.devicePixelRatio) / widthStep),
                    fit,
                };
            }
            setUrl(
                `${asset.file.url}?${Object.entries(params)
                    .filter(([k, v]) => !!v)
                    .map(([k, v]) => `${k}=${v}`)
                    .join("&")}`
            );
        };

        if (!isSvg) {
            //size the first time around
            handleResize();

            //resize if needed
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, []);

    if ((image as FPImage).focalPoint) {
        const focal_point = (image as FPImage).focalPoint.focalPoint;
        const image_size = asset.file.details.image;
        return (
            <img
                ref={ref}
                {...props}
                style={{
                    objectFit: "cover",
                    objectPosition: `${(focal_point.x * 100.0) / image_size.width}% ${
                        (focal_point.y * 100.0) / image_size.height
                    }%`,
                    maxWidth: "100%",
                }}
                src={url}
                alt={asset && asset.description}
                title={asset && asset.title}
            />
        );
    }
    return (
        <img
            ref={ref}
            {...props}
            src={url}
            style={{ maxWidth: "100%" }}
            alt={asset && asset.description}
            title={asset && asset.title}
        />
    );
};
