import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { ImageModel } from "../models";
import * as blockCss from "../styles/block.module.scss";
import { Image, FPImage } from "./image";

interface ImageContainerProps {
    images: Array<ImageModel | FPImage>;
    isCarousel?: boolean;
    className?: string;
    fit?: string;
    showDescription?: boolean;
    css?: typeof blockCss;
}

export const ImageContainer: React.FC<ImageContainerProps> = ({
    images,
    isCarousel,
    className,
    fit,
    showDescription,
    css,
}) => {
    const assets = [] as Array<ImageModel>;
    for (let i = 0; i < images.length; i++) {
        if ((images[i] as ImageModel).file) {
            assets.push(images[i] as ImageModel);
        } else if ((images[i] as FPImage).image.file) {
            assets.push((images[i] as FPImage).image);
        }
    }

    const vidRef = useRef(null);
    useEffect(() => {
        const onScroll = () => {
            if (scrollY > innerHeight) {
                pauseVideo();
            } else {
                playVideo();
            }
        };
        const playVideo = () => {
            // You can use the play method as normal on your video ref
            vidRef.current.play();
        };

        const pauseVideo = () => {
            // Pause as well
            vidRef.current.pause();
        };

        if (assets.find((m) => m.file.contentType.startsWith("video"))) {
            window.addEventListener("scroll", onScroll);

            return function cleanup() {
                window.removeEventListener("scroll", onScroll);
            };
        }
    }, []);

    return (
        <div className={classNames((css && css.imageContainer) || blockCss.imageContainer, className)}>
            {assets.map((m, i) =>
                m.file.contentType.startsWith("video") ? (
                    <video ref={vidRef} key={i} autoPlay muted loop>
                        <source src={m.file.url} type="video/mp4" />
                    </video>
                ) : (
                    <div className={(css && css.imageWrapper) || blockCss.imageWrapper} key={i}>
                        <Image image={m} fit={fit} />
                        {showDescription && (
                            <div className={(css && css.imageDescription) || blockCss.imageDescription}>
                                <p>{m.description}</p>
                            </div>
                        )}
                    </div>
                )
            )}
            {isCarousel &&
                images.map((m, i) => (
                    <div className={(css && css.imageWrapper) || blockCss.imageWrapper} key={`c-${i}`}>
                        <Image image={m} fit={fit} />
                    </div>
                ))}
        </div>
    );
};
